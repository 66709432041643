import React, { useEffect, useState } from "react";
import { Select } from "antd";
import Admin from "./Admin";
import SubTeacher from "./SubTeacher";
import Attendance from "./Attendance";
import { useTranslation } from "react-i18next";
import UserAuthInfo from "../utils/UserAuthInfo";

interface UserInfo {
	email: string | undefined;
	id: string | undefined;
	role: string | undefined;
}

const { Option } = Select;

export const Attendances = () => {
	const [selectedOption, setSelectedOption] = useState<string>("1");
	const [userRole, setUserRole] = useState<string | null>(null);
	const [t, i18n] = useTranslation("global");
	const [loginData, setLoginData] = useState<any | null>(null);

	const handleSelectChange = (value: string) => {
		setSelectedOption(value);
	};

	useEffect(() => {
		const fetchUserInfo = async () => {
			const userInfo: UserInfo | null = await UserAuthInfo();
			if (userInfo && userInfo.role) {
				setUserRole(userInfo.role);
				setLoginData(userInfo);
			}
		};
		fetchUserInfo();
	}, [userRole]);

	return (
		<div>
			{userRole === "teacher" && (
				<>
					<Select
						defaultValue="1"
						onChange={handleSelectChange}
						style={{ width: "200px", margin: "0px 56px" }}
					>
						<Option value="1">{t("teacher")}</Option>
						<Option value="2">{t("subTeacher")}</Option>
					</Select>
					<div style={{ marginTop: "20px" }}>
						{selectedOption === "1" && <Attendance />}
						{selectedOption === "2" && <SubTeacher />}
					</div>
				</>
			)}
			{userRole === "admin" && <Admin userInfo={loginData} />}
		</div>
	);
};

export default Attendances;
