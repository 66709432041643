import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Table, Tag, Input, Modal, InputNumber, Popover, Card, Menu, Dropdown, Button } from "antd";
import type { ColumnsType } from "antd/es/table";
import { SessionsContext } from "../useContext/SessionsContext";
import {
	ClockCircleOutlined,
	EditOutlined,
	SearchOutlined,
	FilterOutlined,
} from "@ant-design/icons";
import { AlertsContext } from "../useContext/AlertContext";
import {
	updateAttendanceApi,
	getAttendanceApi,
	getAttendanceInSectionApi,
	updateAttendanceAdminApi,
} from "../api";
import Sessions from "./Sessions";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { format } from "date-fns";
import { FilterDropdownProps } from "antd/es/table/interface";
import type { ColumnType } from "antd/es/table";
import { debounce } from "lodash";

type rec = {
	studentId: string;
	attendance: string;
	firstname: string;
	notes: string;
	justified: boolean;
	duration: number | null;
	[key: string]: any;
};

export const Attendance = () => {
	const [dataSource, setDataSource] = useState([]);
	const [loading, setLoading] = useState(false);
	const session = useContext(SessionsContext);
	const alerts = useContext(AlertsContext);
	const [editNote, setEditNote] = useState<rec>({
		studentId: "",
		attendance: "",
		firstname: "",
		notes: "",
		justified: false,
		duration: 0,
	});
	const [isEditing, setIsEditing] = useState(false);
	const [t] = useTranslation("global");
	const today: Date = new Date();
	const todayDate: string = format(today, "yyyy-MM-dd");
	const [dateValue, setDateValue] = useState(() => dayjs(todayDate));
	const [searchInput, setSearchInput] = useState<any>(null);
	const debouncedUpdateStatus = debounce((record: rec) => {
		updateStatus(record);
	}, 1200);

	const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
		confirm();
	};

	const getTagColor = (attendance: string) => {
		let color = "";

		switch (attendance) {
			case "present":
				color = "success";
				break;
			case "late":
				color = "warning";
				break;
			case "exited":
				color = "blue";
				break;
			case "absent":
				color = "error";
				break;
			default:
				color = "";
				break;
		}

		return color;
	};

	useEffect(() => {
		getAttendance();
	}, [session]);

	const getColumnSearchProps = (dataIndex: string) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}: FilterDropdownProps) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={`${t("searchFor")}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: "block" }}
				/>
				<Button
					type="primary"
					onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
					{t("search")}
				</Button>
				<Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					{t("clear")}
				</Button>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? "#000000" : "#ffffff" }} />
		),
		onFilter: (value: string, record: rec) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

		onFilterDropdownVisibleChange: (visible: boolean) => {
			if (visible && searchInput) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
	});

	const handleReset = (clearFilters?: () => void) => {
		if (clearFilters) {
			clearFilters();
		}
	};

	const columns: ColumnsType<rec> = [
		{
			title: "",
			dataIndex: "key",
			rowScope: "row",
			render: (_, __, index) => index + 1,
			width: 60,
			fixed: "left",
		},
		{
			key: "1",
			title: `${t("name")}`,
			dataIndex: "firstname",
			fixed: "left",
			align: "right",
			...(getColumnSearchProps("firstname") as ColumnType<rec>),
		},
		{
			key: "2",
			title: `${t("attendance")}`,
			dataIndex: "attendance",
			width: 150,
			filters: [
				{ text: t("present"), value: "present" },
				{ text: t("late"), value: "late" },
				{ text: t("exited"), value: "exited" },
				{ text: t("absent"), value: "absent" },
			],
			filterIcon: (filtered: boolean) => (
				<FilterOutlined style={{ color: filtered ? "#000000" : "#ffffff" }} />
			),
			onFilter: (value: boolean | React.Key, record: rec) => record.attendance === value.toString(),
			render: (action: any, record: rec) => (
				<Dropdown overlay={dropdownMenu(record)} trigger={["click"]}>
					<Tag color={getTagColor(record.attendance)}>{t(record.attendance)}</Tag>
				</Dropdown>
			),
		},
		{
			key: "3",
			title: `${t("justified")}`,
			dataIndex: "justified",
			width: 100,
			render: (action: any, record: rec) => {
				if (record.attendance !== "present" && record.attendance == "absent") {
					return (
						<Tag
							onClick={() => updateJustified(record)}
							color={record.justified ? "success" : "error"}
						>
							{record.justified ? `${t("justified")}` : `${t("notJustified")}`}
						</Tag>
					);
				}
			},
		},
		{
			key: "4",
			title: `${t("duration")}`,
			dataIndex: "duration",
			width: 180,
			render: (action: any, record: rec) => {
				const onChange = (value: number | null) => {
					record.duration = value;
					debouncedUpdateStatus(record);
				};

				if (record.attendance === "late") {
					return (
						<InputNumber
							addonBefore={<ClockCircleOutlined />}
							addonAfter={t("min")}
							min={1}
							max={59}
							value={record.duration}
							onChange={onChange}
						/>
					);
				}
			},
		},
		{
			key: "5",
			title: `${t("notes")}`,
			dataIndex: "notes",
			render: (action: any, record: rec) => {
				if (record.attendance !== "present") {
					return (
						<>
							<Popover content={record.notes} trigger="hover">
								<EditOutlined
									onClick={() => onEditNotes(record)}
									style={{
										fontSize: 20,
										color: record.notes ? "#87d068" : "#000000",
									}}
								/>
							</Popover>
						</>
					);
				}
			},
		},
	];

	const onEditNotes = (record: rec) => {
		setIsEditing(true);
		setEditNote(record);
	};

	const getAttendance = () => {
		if (!session.sessions) return;
		setLoading(true);
		getAttendanceInSectionApi({
			sectionId: session.sessions?.sectionId,
			sessionStart: session.sessions?.start,
		}).then((res) => {
			setDataSource(
				res.data.Items.sort((a: any, b: any) => a.firstname.localeCompare(b.firstname, "ar"))
			);
			setLoading(false);
		});
	};

	const dropdownMenu = (record: rec) => (
		<Menu onClick={({ key }) => handleDropdownClick(record, key)}>
			<Menu.Item key="present">{t("present")}</Menu.Item>
			<Menu.Item key="late">{t("late")}</Menu.Item>
			<Menu.Item key="exited">{t("exited")}</Menu.Item>
			<Menu.Item key="absent">{t("absent")}</Menu.Item>
		</Menu>
	);

	const handleDropdownClick = (record: rec, key: string) => {
		record.attendance = key;
		const params = {
			sessionId: `${session.sessions?.sectionId}#${session.sessions?.start}`,
			studentId: record.studentId,
			attendance: record.attendance,
			justified: record.justified,
			notes: record.notes,
			duration: record.duration,
		};
		if (record.attendance === "absent") {
			params.justified = record.justified;
		} else {
			params.justified = false;
		}
		setLoading(true);
		updateAttendanceAdminApi(params)
			.then(function (response) {
				if (response.status === 200) {
					//getAttendance();
					alerts.setAlerts({ message: response.data.message });
				}
			})
			.catch(function (error) {
				alerts.setAlerts({ message: error });
			})
			.finally(() => setLoading(false));
	};

	const updateStatus = (record: rec) => {
		const params = {
			sessionId: `${session.sessions?.sectionId}#${session.sessions?.start}`,
			studentId: record.studentId,
			attendance: record.attendance,
			justified: record.justified,
			notes: record.notes,
			duration: record.duration,
		};
		if (record.attendance === "absent") {
			params.justified = record.justified;
		} else {
			params.justified = false;
		}
		setLoading(true);
		updateAttendanceAdminApi(params)
			.then(function (response) {
				if (response.status === 200) {
					//getAttendance();
					alerts.setAlerts({ message: response.data.message });
				}
			})
			.catch(function (error) {
				alerts.setAlerts({ message: error });
			});
		setLoading(false);
		setIsEditing(false);
	};

	const updateNotes = (record: rec) => {
		const params = {
			sessionId: `${session.sessions?.sectionId}#${session.sessions?.start}`,
			studentId: record.studentId,
			attendance: record.attendance,
			justified: record.justified,
			notes: record.notes,
			duration: record.duration,
		};
		if (record.attendance === "absent") {
			params.justified = record.justified;
		} else {
			params.justified = false;
		}
		setLoading(true);
		updateAttendanceAdminApi(params)
			.then(function (response) {
				if (response.status === 200) {
					getAttendance();
					alerts.setAlerts({ message: response.data.message });
				}
			})
			.catch(function (error) {
				alerts.setAlerts({ message: error });
			})
			.finally(() => setLoading(false));
		setIsEditing(false);
	};

	const updateJustified = (record: rec) => {
		if (record.justified === false) {
			record.justified = true;
		} else if (record.justified === true) {
			record.justified = false;
		}

		const params = {
			sessionId: `${session.sessions?.sectionId}#${session.sessions?.start}`,
			studentId: record.studentId,
			attendance: record.attendance,
			justified: record.justified,
			notes: record.notes,
			duration: record.duration,
		};
		if (record.attendance === "absent") {
			params.justified = record.justified;
		} else {
			params.justified = false;
		}
		setLoading(true);
		updateAttendanceAdminApi(params)
			.then(function (response) {
				if (response.status === 200) {
					//getAttendance();
					alerts.setAlerts({ message: response.data.message });
				}
			})
			.catch(function (error) {
				alerts.setAlerts({ message: error });
			});
		setLoading(false);
		setIsEditing(false);
	};

	return (
		<>
			<Sessions />
			{(dateValue.day() === 6 || dateValue.day() === 5) && dataSource.length === 0 ? (
				<div style={{ margin: "auto", padding: "12px" }}>{t("thereIsNoDataForTheWeekend")}</div>
			) : (
				<>
					{dataSource.length !== 0 ? (
						<div style={{ padding: "35px 0px 0px 0px" }}>
							<Table
								loading={loading}
								columns={columns}
								dataSource={dataSource}
								pagination={false}
								scroll={{ x: 1000, y: 500 }}
							></Table>
							<Modal
								title={t("note")}
								visible={isEditing}
								centered
								okText={t("save")}
								cancelText={t("cancel")}
								onOk={() => updateNotes(editNote)}
								onCancel={() => setIsEditing(false)}
							>
								<Input
									value={editNote.notes}
									onChange={(e: any) =>
										setEditNote((pre) => {
											return { ...pre, notes: e.target.value };
										})
									}
								/>
							</Modal>
						</div>
					) : (
						<>
							<Card>
								<Card.Grid
									style={{
										width: "100%",
										textAlign: "center",
										fontSize: 20,
										border: "1px solid black",
										borderRadius: 25,
									}}
								>
									{t("thereIsNoData")}
								</Card.Grid>
							</Card>
						</>
					)}
				</>
			)}
		</>
	);
};
export default Attendance;
