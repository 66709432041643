import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

import { baseURL } from "../utils/constants";

const Api = axios.create({ baseURL: baseURL });

Api.interceptors.request.use(
	async (config) => {
		const authToken = (await fetchAuthSession()).tokens!.idToken!.toString();
		config.headers.Authorization = authToken;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

Api.interceptors.response.use(
	(response) => {
		if (response.status == 200) {
		}
		return response;
	},
	(err) => {
		if (err?.message == "Network Error") {
		}
	}
);

export const updateAttendanceApi = (data: any) => Api.post(`attendance`, data);

export const getAttendanceApi = (sessionStart: any) => Api.get(`attendance/${sessionStart}`);

export const getSessionsApi = () => Api.get(`sessions`);
export const getAttendanceInSectionApi = (data: { sectionId: string; sessionStart: string }) =>
	Api.post(`section/attendance`, data);

export const getLessonsScheduleApi = (instrcutorId: number, headers: any) =>
	Api.get(`schedule/${instrcutorId}`, headers);

export const getSectionsApi = () => Api.get(`sections`);

export const getSectionsV2Api = () => Api.get(`sectionsV2`);

export const getSecSessionsApi = (data: any) => Api.post(`section/sessions`, data);

export const getSessionAttendanceApi = (data: any) => Api.post(`session/attendance`, data);

export const updateAttendanceAdminApi = (data: any) => Api.post(`attendance`, data);

export const uploadTimeScheduleApi = (data: any) =>
	Api.post(`school/schedule`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});

export const DownloadSchoolScheduleApi = (s3location: string) =>
	Api.get(`school/schedule?s3location=${encodeURIComponent(s3location)}`);

export const getAbsenceReportApi = (data: {
	sectionId: string;
	startMonth: string;
	endMonth: string;
}) =>
	Api.post(`getAbsenceReport`, data, {
		responseType: "blob",
	});

export const getSchoolMonthlyStatistics = (data: { month: string }) =>
	Api.post(`getAbsenceMonthlyStatistics`, data, {
		responseType: "blob",
	});

export const downloadDailyAbsenceReportApi = (data: { sectionId: string; start: string }) =>
	Api.post(`getDailyAbsenceReport`, data, {
		responseType: "blob",
	});

export const getSchoolSettingsApi = () => Api.get(`/admin/settings`);
export const getTimeScheduleVersionsApi = () => Api.get(`/school/scheduleVersions`);

export const editSchoolSettingsApi = (data: any) => Api.post(`/admin/settings`, data);

export const getSectionDailyAttApi = (data: { sectionId: string; dayStart: string }) =>
	Api.post(`/section/daily-attendance`, data);

export const getAttendanceChangesApi = () => Api.get(`/attendanceChanges`);
