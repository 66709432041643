import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Upload, Button, message, Table, AutoComplete } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import {
	editSchoolSettingsApi,
	getSchoolSettingsApi,
	getTimeScheduleVersionsApi,
	uploadTimeScheduleApi,
	DownloadSchoolScheduleApi,
} from "../api";

const { TextArea } = Input;
const { Option } = AutoComplete;

const SchoolSettings = () => {
	const [t, i18n] = useTranslation("global");
	const [timezone, setTimezone] = useState("");
	const [scheduleVer, setScheduleVer] = useState("");
	const [filteredTimezones, setFilteredTimezones] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [schedules, setSchedules] = useState([]);

	useEffect(() => {
		// Function to fetch school settings
		const fetchSchoolSettings = async () => {
			try {
				const response = await getSchoolSettingsApi();
				const { timezone, scheduleVer } = response.data;
				setTimezone(timezone);
				setScheduleVer(scheduleVer);
			} catch (error) {
				message.error("Fghiled to fetch school settings");
			}
		};

		fetchSchoolSettings();
	}, []);

	// Fetch schedules when component loads
	const fetchSchedules = async () => {
		try {
			const response = await getTimeScheduleVersionsApi();
			const sortedSchedules = response.data.sort((a: any, b: any) => b.uploaded - a.uploaded);
			setSchedules(sortedSchedules);
		} catch (error) {
			message.error("Failed to fetch schedules");
		}
	};
	useEffect(() => {
		fetchSchedules();
	}, []); // Dependency array includes schoolId to refetch if it changes

	// Function to handle file upload
	const handleFileUpload = async (file: File) => {
		// Create an instance of FormData
		const formData = new FormData();
		// Append the file to the FormData instance, using the field name 'file'
		formData.append("file", file);

		try {
			const response = await uploadTimeScheduleApi(formData);

			message.success(`${file.name} file uploaded successfully.`);
			setScheduleVer(response.data.version);
			fetchSchedules();
		} catch (error) {
			// Handle error here
			//console.error('Error uploading file:', error);
		}
	};

	// Function to handle file upload error
	const handleFileUploadError = (error: any) => {
		// Handle upload error here
		//console.error("File upload error:", error);
		message.error(`File upload failed: ${error}`);
	};

	// Function to handle timezone change
	const handleTimezoneChange = (value: string) => {
		setTimezone(value);
	};

	// Function to search timezones
	const handleSearchTimezone = (value: string) => {
		// @ts-ignore
		const filteredOptions = Intl.supportedValuesOf("timeZone").filter((option: string) =>
			option.toLowerCase().includes(value.toLowerCase())
		);
		// @ts-ignore
		setFilteredTimezones(filteredOptions);
	};

	// Example of previous schedules
	const examplePreviousSchedules = [
		{
			schoolId: "1",
			version: "22-23#12",
			s3location: "schools/1/admin/schedules/schedule#22-23#12.xlsm",
			uploaded: 1707652957114,
		},
		// Add more schedules as needed
	];

	// Function to handle file download
	const handleFileDownload = (data: any) => {
		DownloadSchoolScheduleApi(data.s3location)
			.then((res) => {
				window.location.href = res.data.url;
				message.success("File Downloaded successfully.");
			})
			.catch((error) => {
				message.error("Failed to Download the File");
			});
	};

	const handleUpdateSettings = async () => {
		try {
			await editSchoolSettingsApi({ timezone });
			message.success("School settings updated successfully.");
		} catch (error) {
			message.error("Failed to update school settings");
		}
	};

	return (
		<div>
			{/* <h2>{t("schoolSettings.title")}</h2>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", maxWidth: "300px" }}>
        <div>
          <label style={{ width: "100%" }}>{t("schoolSettings.timezone")}</label>
          <AutoComplete
            style={{ width: "100%" }}
            value={timezone}
            onChange={handleTimezoneChange}
            onSearch={handleSearchTimezone}
            placeholder={t("schoolSettings.timezonePlaceholder")}
          >
            {filteredTimezones.map(tz => (
              <Option key={tz} value={tz}>{tz}</Option>
            ))}
          </AutoComplete>
        </div>
        <Button type="primary" onClick={handleUpdateSettings} style={{ marginTop: '10px' }}>
          Save Settings
        </Button>
      </div> */}
			<div style={{ marginTop: "20px" }}>
				<div>
					<h2>{t("schoolSettings.uploadSchedule")}</h2>
				</div>
				<div>
					<Upload
						beforeUpload={(file) => {
							// Instead of uploading, just store the file for later
							setSelectedFile(file as any);
							// Prevent default upload
							return false;
						}}
						onChange={(info) => {
							if (info.file.status === "removed") {
								// Handle file removal
								setSelectedFile(null);
							}
						}}
						accept=".xlsx, .xls, .xlsm"
					>
						<Button icon={<UploadOutlined />}>Select File</Button>
					</Upload>
					<Button
						type="primary"
						onClick={() => selectedFile && handleFileUpload(selectedFile)}
						disabled={!selectedFile}
					>
						Upload File
					</Button>
				</div>
			</div>
			<div style={{ marginTop: "20px", width: "60%" }}>
				<div style={{ marginTop: "20px", width: "100%" }}>
					<h3>{t("schoolSettings.schedules")}</h3>
					<Table
						dataSource={schedules}
						rowKey="version"
						pagination={{ pageSize: 10 }}
						rowClassName={(record) =>
							(record as any).version === scheduleVer ? "highlighted-row" : ""
						}
					>
						<Table.Column title={t("schoolSettings.version")} dataIndex="version" key="version" />
						<Table.Column
							title={t("schoolSettings.uploaded")}
							dataIndex="uploaded"
							key="uploaded"
							render={(text, record) => new Date((record as any).uploaded).toLocaleDateString()}
						/>
						<Table.Column
							title={t("schoolSettings.download")}
							key="action"
							render={(text, record) => (
								<Button
									type="link"
									icon={<DownloadOutlined />}
									onClick={() => handleFileDownload(record)}
								>
									{t("schoolSettings.download")}
								</Button>
							)}
						/>
					</Table>
				</div>
			</div>
		</div>
	);
};

export default SchoolSettings;
