import React, { useState, useEffect } from "react";
import { DatePicker, Form, Select, Button, message, ConfigProvider, Typography, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ar";
import { useTranslation } from "react-i18next";
import {
	getSectionsApi,
	getAbsenceReportApi,
	getSchoolMonthlyStatistics,
	getSectionsV2Api,
} from "../api";

import locale from "antd/locale/ar_EG";
import "dayjs/locale/ar-dz";

dayjs.locale("ar-dz");

type sectionsType = {
	sectionId: string;
	section: string;
};

const { RangePicker } = DatePicker;
const { Title } = Typography;
const AbsenceReport = () => {
	const [sectionsData, setSectionsData] = useState<sectionsType[]>([]);
	const [selectedSection, setSelectedSection] = useState<string | undefined>(undefined);
	const [schoolReportMonth, setSchoolReportMonth] = useState(() => dayjs());

	const { Option } = Select;
	const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
	const [t] = useTranslation("global");

	useEffect(() => {
		getSections();
	}, []);

	const handleSectionChange = (value: string) => {
		setSelectedSection(value);
	};

	const getAbsenceReport = () => {
		if (!selectedSection || !dateRange) return;

		const startMonth = dateRange[0] ? dateRange[0].format("YYYY-MM") : "";
		const endMonth = dateRange[1] ? dateRange[1].format("YYYY-MM") : "";

		const sectionId = selectedSection?.slice(-3); // Extract last three digits

		// Display message when download starts
		message.loading({
			content: t("absenceReport.attendanceReportDownloadIsInProgress"),
			key: "download",
		});

		getAbsenceReportApi({
			sectionId,
			startMonth,
			endMonth,
		})
			.then((res) => {
				const filename =
					res.headers["content-disposition"]
						?.split("filename=")[1]
						?.split(";")[0]
						?.replace(/"/g, "") || "attendance-report.xlsx";
				const fileBlob = new Blob([res.data], {
					type: res.headers["content-type"],
				});
				const fileURL = URL.createObjectURL(fileBlob);
				const link = document.createElement("a");
				link.href = fileURL;
				link.setAttribute("download", filename);
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(link.href);
				document.body.removeChild(link);

				// Display message when download completes
				message.success({
					content: t("absenceReport.attendanceReportDownloadCompleted"),
					key: "download",
					duration: 2,
				});
			})
			.catch(() => {
				// Display message if download fails
				message.error({
					content: t("absenceReport.failedToDownloadAttendanceReport"),
					key: "download",
					duration: 2,
				});
			});
	};

	const getSchoolReport = () => {
		if (!schoolReportMonth) return;

		const month = schoolReportMonth.format("YYYY-MM");

		// Display message when download starts
		message.loading({
			content: t("absenceReport.attendanceReportDownloadIsInProgress"),
			key: "download",
		});

		getSchoolMonthlyStatistics({ month })
			.then((res) => {
				const filename =
					res.headers["content-disposition"]
						?.split("filename=")[1]
						?.split(";")[0]
						?.replace(/"/g, "") || "attendance-report.xlsx";
				const fileBlob = new Blob([res.data], {
					type: res.headers["content-type"],
				});
				const fileURL = URL.createObjectURL(fileBlob);
				const link = document.createElement("a");
				link.href = fileURL;
				link.setAttribute("download", filename);
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(link.href);
				document.body.removeChild(link);

				// Display message when download completes
				message.success({
					content: t("absenceReport.attendanceReportDownloadCompleted"),
					key: "download",
					duration: 2,
				});
			})
			.catch(() => {
				// Display message if download fails
				message.error({
					content: t("absenceReport.failedToDownloadAttendanceReport"),
					key: "download",
					duration: 2,
				});
			});
	};

	const getSections = () => {
		getSectionsV2Api().then((res) => {
			setSectionsData(res.data.sections);
		});
	};

	const handleButtonClick = () => {
		getAbsenceReport();
	};

	const handleSchoolReportDownload = () => {
		getSchoolReport();
	};

	const onRangeChange = (dates: any) => {
		setDateRange(dates);
	};

	const onMonthSelect = (value: Dayjs) => {
		setSchoolReportMonth(value);
	};

	return (
		<>
			<ConfigProvider locale={locale}>
				<Title level={4}>الإحصائيات الشهرية للمؤسسة</Title>
				<Form layout="inline" style={{ margin: "auto" }}>
					<Space direction="horizontal" style={{ width: "100%" }} wrap>
						<Form.Item label={t("date")}>
							<DatePicker
								picker="month"
								format="YYYY-MM"
								onSelect={onMonthSelect}
								style={{ width: 200 }}
								disabledDate={(current) => {
									return current && current > dayjs().endOf("day");
								}}
							/>
						</Form.Item>

						<Form.Item>
							<Button type="primary" onClick={handleSchoolReportDownload}>
								{t("download")}
							</Button>
						</Form.Item>
					</Space>
				</Form>

				<Title level={4}>متابعة الغياب اليومي للطلاب</Title>
				<Form layout="inline" style={{ margin: "auto" }}>
					<Space direction="horizontal" style={{ width: "100%" }} wrap>
						<Form.Item label={t("section")}>
							<Select
								showSearch
								style={{ width: 200 }}
								placeholder="اختر الفصل"
								optionFilterProp="children"
								onChange={handleSectionChange}
								value={selectedSection}
							>
								{sectionsData.map((item: sectionsType) => (
									<Option key={item.sectionId} value={item.sectionId}>
										{item.section}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label={t("date")}>
							<RangePicker
								format="YYYY-MM"
								picker="month"
								value={dateRange}
								onChange={onRangeChange}
								placeholder={["الشهر من", "الشهر إلى"]}
								disabledDate={(current: Dayjs) => current > dayjs().endOf("month")}
							/>
						</Form.Item>
						<Form.Item>
							<Button type="primary" onClick={handleButtonClick}>
								{t("download")}
							</Button>
						</Form.Item>
					</Space>
				</Form>
			</ConfigProvider>
		</>
	);
};

export default AbsenceReport;
