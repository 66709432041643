import React from "react";
import { Amplify } from "aws-amplify";
import { Authenticator, View, Image} from "@aws-amplify/ui-react";

import Contents from "./components/Contents";
import { AlertsProvider } from "./useContext/AlertContext";
import { SessionsProvider } from "./useContext/SessionsContext";

import '@aws-amplify/ui-react/styles.css';
import "./App.css";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID || "",
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || "",
    }
  }
});

const components = {
  Header() {  
    return (
      <View textAlign="center" style={{padding: 40}}>
        <Image
          alt="Amplify logo"
          src="/eedara_logo.png"
          width={200}
        />
      </View>
    );
  },

  Footer() {

    return (
      <View textAlign="center" style={{paddingTop: 50}}>
        <div>
          &copy; All Rights Reserved
        </div>
      </View>
    );
  },
}

function App() {
  return (
    <Authenticator
      loginMechanisms={["email"]}
      hideSignUp={true}
      components={components}
    >
      <SessionsProvider>
        <AlertsProvider>
          <Contents />
        </AlertsProvider>
      </SessionsProvider>
    </Authenticator>
  );
}

export default App;