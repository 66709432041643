import React, { useState, useEffect, useContext } from "react";
import {
	DatePicker,
	Form,
	Select,
	Steps,
	Col,
	Row,
	Table,
	Modal,
	Input,
	InputNumber,
	Popover,
	Tag,
	Card,
	Menu,
	Dropdown,
	Spin,
	message,
	Button,
} from "antd";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { SessionsType, SessionsContext } from "../useContext/SessionsContext";
import {
	getSectionsApi,
	getSecSessionsApi,
	updateAttendanceAdminApi,
	getAttendanceInSectionApi,
	getSectionsV2Api,
} from "../api";
import {
	ClockCircleOutlined,
	EditOutlined,
	SearchOutlined,
	FilterOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { FilterDropdownProps } from "antd/es/table/interface";
import type { ColumnType } from "antd/es/table";
import { debounce } from "lodash";
import { AlertsContext } from "../useContext/AlertContext";

type sectionsType = {
	sectionId: string;
	section: string;
};
type rec = {
	studentId: string;
	attendance: string;
	firstname: string;
	notes: string;
	justified: boolean;
	duration: number | null;
	[key: string]: any;
};

const SubTeacher = () => {
	const [sectionsData, setSectionsData] = useState<sectionsType[]>([]);
	const [attendanceData, setAttendanceData] = useState([]);
	const [selectedSection, setSelectedSection] = useState<string>("");
	const alerts = useContext(AlertsContext);
	const [loadingSections, setLoadingSections] = useState(false);
	const [loadingAttendance, setLoadingAttendance] = useState(false);
	const { Option } = Select;
	const today: Date = new Date();
	const todayDate: string = format(today, "yyyy-MM-dd");
	const [dateValue, setDateValue] = useState(() => dayjs());
	const currentTime = new Date();
	const currentHours = currentTime.getHours();
	const currentMinutes = currentTime.getMinutes();
	const computeDefaultSession = () => {
		if (currentHours === 7 && currentMinutes >= 10 && currentMinutes <= 55) {
			return 0;
		} else if (currentHours === 8 && currentMinutes >= 0 && currentMinutes <= 45) {
			return 1;
		} else if (
			(currentHours === 8 && currentMinutes >= 50) ||
			(currentHours === 9 && currentMinutes <= 35)
		) {
			return 2;
		} else if (currentHours === 10 && currentMinutes >= 0 && currentMinutes <= 45) {
			return 3;
		} else if (
			(currentHours === 10 && currentMinutes >= 50) ||
			(currentHours === 11 && currentMinutes <= 35)
		) {
			return 4;
		} else if (
			(currentHours === 11 && currentMinutes >= 55) ||
			(currentHours === 12 && currentMinutes <= 40)
		) {
			return 5;
		} else if (
			(currentHours === 12 && currentMinutes > 40) ||
			(currentHours === 13 && currentMinutes <= 25)
		) {
			return 6;
		} else {
			return 9;
		}
	};

	const [currentSession, setCurrentSession] = useState<number>(computeDefaultSession());
	const [t] = useTranslation("global");
	const [secSessionsData, setSecSessionsData] = useState([]);
	const session = useContext(SessionsContext);
	const [editNote, setEditNote] = useState<rec>({
		studentId: "",
		attendance: "",
		firstname: "",
		notes: "",
		justified: false,
		duration: 0,
	});
	const [isEditing, setIsEditing] = useState(false);
	const [searchInput, setSearchInput] = useState<any>(null);
	session.setSessions(secSessionsData[currentSession]);
	const debouncedUpdateStatus = debounce((record: rec) => {
		updateStatus(record);
	}, 1200);

	const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
		confirm();
	};

	const onChange = (value: number) => {
		setCurrentSession(value);
	};

	const onSelect = (value: Dayjs) => {
		setDateValue(value);
	};
	const onPanelChange = (value: Dayjs) => {
		setDateValue(value);
	};

	useEffect(() => {
		getSections();
	}, []);

	useEffect(() => {
		selectedSection && dateValue && getSessions();
	}, [selectedSection]);

	useEffect(() => {
		selectedSection && dateValue && getSessions();
	}, [dateValue]);

	useEffect(() => {
		session.sessions?.start && session.sessions?.section && getAttendance();
	}, [session.sessions?.start, session.sessions?.section]);

	const data = sectionsData;
	const handleSectionChange = (value: string) => {
		setSelectedSection(value);
	};

	const getTagColor = (attendance: string) => {
		let color = "";

		switch (attendance) {
			case "present":
				color = "success";
				break;
			case "late":
				color = "warning";
				break;
			case "exited":
				color = "blue";
				break;
			case "absent":
				color = "error";
				break;
			default:
				color = "";
				break;
		}

		return color;
	};

	const getColumnSearchProps = (dataIndex: string) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}: FilterDropdownProps) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={`${t("searchFor")}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: "block" }}
				/>
				<Button
					type="primary"
					onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
					{t("search")}
				</Button>
				<Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					{t("clear")}
				</Button>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? "#000000" : "#ffffff" }} />
		),
		onFilter: (value: string, record: rec) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

		onFilterDropdownVisibleChange: (visible: boolean) => {
			if (visible && searchInput) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
	});

	const handleReset = (clearFilters?: () => void) => {
		if (clearFilters) {
			clearFilters();
		}
	};

	const columns: ColumnsType<rec> = [
		{
			title: "",
			dataIndex: "key",
			rowScope: "row",
			render: (_, __, index) => index + 1,
			width: 60,
			fixed: "left",
		},
		{
			key: "1",
			title: `${t("name")}`,
			dataIndex: "firstname",
			fixed: "left",
			align: "right",
			...(getColumnSearchProps("firstname") as ColumnType<rec>),
		},
		{
			key: "2",
			title: `${t("attendance")}`,
			dataIndex: "attendance",
			width: 150,
			filters: [
				{ text: t("present"), value: "present" },
				{ text: t("late"), value: "late" },
				{ text: t("exited"), value: "exited" },
				{ text: t("absent"), value: "absent" },
			],
			filterIcon: (filtered: boolean) => (
				<FilterOutlined style={{ color: filtered ? "#000000" : "#ffffff" }} />
			),
			onFilter: (value: boolean | React.Key, record: rec) => record.attendance === value.toString(),
			render: (action: any, record: rec) => (
				<Dropdown overlay={dropdownMenu(record)} trigger={["click"]}>
					<Tag color={getTagColor(record.attendance)}>{t(record.attendance)}</Tag>
				</Dropdown>
			),
		},
		{
			key: "3",
			title: `${t("justified")}`,
			dataIndex: "justified",
			width: 100,
			render: (action: any, record: rec) => {
				if (record.attendance !== "present" && record.attendance == "absent") {
					return (
						<Tag
							onClick={() => updateJustified(record)}
							color={record.justified ? "success" : "error"}
						>
							{record.justified ? `${t("justified")}` : `${t("notJustified")}`}
						</Tag>
					);
				}
			},
		},
		{
			key: "4",
			title: `${t("duration")}`,
			dataIndex: "duration",
			width: 180,
			render: (action: any, record: rec) => {
				const onChange = (value: number | null) => {
					record.duration = value;
					debouncedUpdateStatus(record);
				};

				if (record.attendance === "late") {
					return (
						<InputNumber
							addonBefore={<ClockCircleOutlined />}
							addonAfter={t("min")}
							min={1}
							max={59}
							value={record.duration}
							onChange={onChange}
						/>
					);
				}
			},
		},
		{
			key: "5",
			title: `${t("notes")}`,
			dataIndex: "notes",
			render: (action: any, record: rec) => {
				if (record.attendance !== "present") {
					return (
						<>
							<Popover content={record.notes} trigger="hover">
								<EditOutlined
									onClick={() => onEditNotes(record)}
									style={{
										fontSize: 20,
										color: record.notes ? "#87d068" : "#000000",
									}}
								/>
							</Popover>
						</>
					);
				}
			},
		},
	];

	const dropdownMenu = (record: rec) => (
		<Menu onClick={({ key }) => handleDropdownClick(record, key)}>
			<Menu.Item key="present">{t("present")}</Menu.Item>
			<Menu.Item key="late">{t("late")}</Menu.Item>
			<Menu.Item key="exited">{t("exited")}</Menu.Item>
			<Menu.Item key="absent">{t("absent")}</Menu.Item>
		</Menu>
	);

	const handleDropdownClick = (record: rec, key: string) => {
		record.attendance = key;
		const params = {
			sessionId: `${session.sessions?.section}#${session.sessions?.start}`,
			studentId: record.studentId,
			attendance: record.attendance,
			justified: record.justified,
			notes: record.notes,
			duration: record.duration,
		};
		if (record.attendance === "absent") {
			params.justified = record.justified;
		} else {
			params.justified = false;
		}
		setLoadingAttendance(true);
		updateAttendanceAdminApi(params)
			.then(function (response) {
				if (response.status === 200) {
					//getAttendance();
					alerts.setAlerts({ message: response.data.message });
				}
			})
			.catch(function (error) {
				alerts.setAlerts({ message: error });
			})
			.finally(() => setLoadingAttendance(false));
	};

	const updateStatus = (record: rec) => {
		const params = {
			sessionId: `${session.sessions?.section}#${session.sessions?.start}`,
			studentId: record.studentId,
			attendance: record.attendance,
			justified: record.justified,
			notes: record.notes,
			duration: record.duration,
		};
		if (record.attendance === "absent") {
			params.justified = record.justified;
		} else {
			params.justified = false;
		}
		setLoadingAttendance(true);
		updateAttendanceAdminApi(params)
			.then(function (response) {
				if (response.status === 200) {
					//getAttendance();
					alerts.setAlerts({ message: response.data.message });
				}
			})
			.catch(function (error) {
				alerts.setAlerts({ message: error });
			});
		setLoadingAttendance(false);
		setIsEditing(false);
	};

	const updateNotes = (record: rec) => {
		const params = {
			sessionId: `${session.sessions?.sectionId}#${session.sessions?.start}`,
			studentId: record.studentId,
			attendance: record.attendance,
			justified: record.justified,
			notes: record.notes,
			duration: record.duration,
		};
		if (record.attendance === "absent") {
			params.justified = record.justified;
		} else {
			params.justified = false;
		}
		setLoadingAttendance(true);
		updateAttendanceAdminApi(params)
			.then(function (response) {
				if (response.status === 200) {
					getAttendance();
					alerts.setAlerts({ message: response.data.message });
				}
			})
			.catch(function (error) {
				alerts.setAlerts({ message: error });
			})
			.finally(() => setLoadingAttendance(false));
		setIsEditing(false);
	};

	const updateJustified = (record: rec) => {
		if (record.justified === false) {
			record.justified = true;
		} else if (record.justified === true) {
			record.justified = false;
		}

		const params = {
			sessionId: `${session.sessions?.section}#${session.sessions?.start}`,
			studentId: record.studentId,
			attendance: record.attendance,
			justified: record.justified,
			notes: record.notes,
			duration: record.duration,
		};
		if (record.attendance === "absent") {
			params.justified = record.justified;
		} else {
			params.justified = false;
		}
		setLoadingAttendance(true);
		updateAttendanceAdminApi(params)
			.then(function (response) {
				if (response.status === 200) {
					//getAttendance();
					alerts.setAlerts({ message: response.data.message });
				}
			})
			.catch(function (error) {
				alerts.setAlerts({ message: error });
			});
		setLoadingAttendance(false);
		setIsEditing(false);
	};

	const getSessions = () => {
		const params = {
			sectionId: selectedSection,
			startTimeFrom: `${dateValue?.format("YYYY-MM-DD")}` + "T00:00:00.000Z",
			startTimeEnd: `${dateValue?.format("YYYY-MM-DD")}` + "T23:59:00.000Z",
		};
		getSecSessionsApi(params)
			.then(function (response) {
				if (response.status === 200) {
					setSecSessionsData(response.data);
					//message.success("Sessions data loaded successfully!", 2);
				}
			})
			.catch(function (error) {
				//message.error("Failed to load sessions data: " + error.message, 2);
			});
	};

	const onEditNotes = (record: rec) => {
		setIsEditing(true);
		setEditNote(record);
	};

	const getAttendance = () => {
		setLoadingAttendance(true);
		if (!session.sessions) return;
		setLoadingAttendance(true);
		getAttendanceInSectionApi({
			sectionId: session.sessions?.section,
			sessionStart: session.sessions?.start,
		})
			.then((res) => {
				if (res.data && res.data.Items) {
					setAttendanceData(
						res.data.Items.sort((a: any, b: any) => a.firstname.localeCompare(b.firstname, "ar"))
					);
				}
				setLoadingAttendance(false);
			})
			.catch((error) => {
				message.error("Failed to load attendance data: " + error.message, 2);
			});
		setLoadingAttendance(false);
	};

	const items = secSessionsData.map((data: SessionsType, index: number) => {
		const start = new Date(data.start);
		const end = new Date(data.end);

		start.setHours(start.getHours() - 6);
		end.setHours(end.getHours() - 6);
		const subtitleNumber = index + 1;

		const sessionInPast =
			start.getHours() < currentHours ||
			(start.getHours() === currentHours && start.getMinutes() <= currentMinutes);
		const sessionInFuture =
			end.getHours() > currentHours ||
			(end.getHours() === currentHours && end.getMinutes() > currentMinutes);
		const clickable = sessionInPast && sessionInFuture;

		return {
			title: `${t("lesson")}` + " " + subtitleNumber,
			subTitle: data.instructorId.substring(13, 15),
			description: [
				start.toTimeString().substring(0, 5),
				" - ",
				end.toTimeString().substring(0, 5),
			],
			disabled: !clickable,
		};
	});

	const allSessionsUnclickable = items.every((item) => item.disabled);
	const itemsLength = allSessionsUnclickable ? 0 : items.length;

	const getSections = () => {
		setLoadingSections(true);
		getSectionsV2Api()
			.then((res) => {
				setSectionsData(res.data);
				setSelectedSection(res.data[0].sectionId);
			})
			.catch((error) => {
				setLoadingSections(false);
			})
			.finally(() => {
				setLoadingSections(false);
			});
	};

	message.config({
		top: 50,
		maxCount: 1,
	});

	return (
		<>
			<Form layout="inline" style={{ margin: "auto", padding: "0px 12px 20px 12px" }}>
				<Form.Item label={t("section")}>
					<Select
						showSearch
						style={{ width: 200 }}
						placeholder="Select a section"
						optionFilterProp="children"
						onChange={handleSectionChange}
						value={selectedSection}
					>
						{data.map((item: sectionsType) => (
							<Option key={item.sectionId} value={item.sectionId}>
								{item.section}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label={t("date")}>
					<DatePicker
						defaultValue={dayjs()}
						onSelect={onSelect}
						onPanelChange={onPanelChange}
						style={{ width: 200 }}
						disabledDate={(current) => {
							return current && !current.isSame(dayjs(), "day");
						}}
					/>
				</Form.Item>
			</Form>
			{(dateValue.day() === 6 || dateValue.day() === 5) && attendanceData.length === 0 ? (
				<div style={{ margin: "auto", padding: "12px" }}>{t("thereIsNoDataForTheWeekend")}</div>
			) : (
				<Row gutter={24}>
					<Col span={24} style={{ paddingBottom: 30, margin: "auto", width: "50%" }}>
						<div>
							<Steps current={currentSession} onChange={onChange} items={items} />
						</div>
					</Col>
					<Col span={24}>
						{loadingAttendance || loadingSections ? (
							<div style={{ textAlign: "center", marginTop: "20px" }}>
								<Spin size="large" />
							</div>
						) : (
							<>
								{itemsLength !== 0 && attendanceData.length !== 0 ? (
									<div>
										<Table
											columns={columns}
											dataSource={attendanceData}
											pagination={false}
											scroll={{ x: 1000, y: 500 }}
										></Table>
										<Modal
											title={t("note")}
											visible={isEditing}
											centered
											okText={t("save")}
											cancelText={t("cancel")}
											onOk={() => updateNotes(editNote)}
											onCancel={() => setIsEditing(false)}
										>
											<Input
												value={editNote.notes}
												onChange={(e: any) =>
													setEditNote((pre) => {
														return { ...pre, notes: e.target.value };
													})
												}
											/>
										</Modal>
									</div>
								) : (
									<Card>
										<Card.Grid
											style={{
												width: "100%",
												textAlign: "center",
												fontSize: 20,
												border: "1px solid black",
												borderRadius: 25,
											}}
										>
											{t("thereIsNoData")}
										</Card.Grid>
									</Card>
								)}
							</>
						)}
					</Col>
				</Row>
			)}
		</>
	);
};

export default SubTeacher;
