import React, { createContext, useState, ReactNode } from "react";

export type SessionsType = {
  instructorId: string
  start: string
  end: string
  section: string
  sectionId: string
};

type SessionsContextType = {
  sessions: SessionsType | null;
  setSessions: React.Dispatch<React.SetStateAction<SessionsType | null>>;
};

type SessionsProvideProps = {
  children: ReactNode;
};

export const SessionsContext = createContext({} as SessionsContextType);

export const SessionsProvider = ({ children }: SessionsProvideProps) => {
  const [sessions, setSessions] = useState<SessionsType | null>({
    instructorId: "ins-001",
    end: "2023-10-21T09:00Z",
    start: "2023-10-21T08:00Z",
    section: "9 / 3",
    sectionId: "22-23#qa#1#9#3",
  });
  return (
    <SessionsContext.Provider value={{ sessions, setSessions }}>
      {children}
    </SessionsContext.Provider>
  );
};
