import React, { createContext, useState, ReactNode } from "react";

export type AlertsType = {
  message: string;
};

type AlertsContextType = {
  alerts: AlertsType | null;
  setAlerts: React.Dispatch<React.SetStateAction<AlertsType | null>>;
};

type AlertsProvideProps = {
  children: ReactNode;
};

export const AlertsContext = createContext({} as AlertsContextType);

export const AlertsProvider = ({ children }: AlertsProvideProps) => {
  const [alerts, setAlerts] = useState<AlertsType | null>({
    message: "message",
  });
  return (
    <AlertsContext.Provider value={{ alerts, setAlerts }}>
      {children}
    </AlertsContext.Provider>
  );
};
