import React, { useRef, useState, useEffect, useContext } from "react";
import {
	DatePicker,
	Form,
	Select,
	Steps,
	Col,
	Row,
	Table,
	Modal,
	Input,
	InputNumber,
	Popover,
	Tag,
	Card,
	Menu,
	Dropdown,
	Spin,
	message,
	Button,
	Space,
	Radio,
	Checkbox,
} from "antd";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { SessionsType, SessionsContext } from "../useContext/SessionsContext";
import {
	getSectionsApi,
	getSecSessionsApi,
	updateAttendanceAdminApi,
	getAttendanceInSectionApi,
	downloadDailyAbsenceReportApi,
	getSectionsV2Api,
} from "../api";
import {
	ClockCircleOutlined,
	EditOutlined,
	RightOutlined,
	LeftOutlined,
	SearchOutlined,
	FilterOutlined,
	DownloadOutlined,
} from "@ant-design/icons";
import type { ColumnsType, ColumnType } from "antd/es/table";
import { FilterDropdownProps } from "antd/es/table/interface";
import { debounce } from "lodash";

type sectionsType = {
	sectionId: string;
	section: string;
};

type rec = {
	studentId: string;
	attendance: string;
	firstname: string;
	notes: string;
	justified: boolean;
	duration: number | null;
	[key: string]: any;
};

type AttendanceByStudent = {
	[studentId: string]: rec;
};

const Admin = (props: any) => {
	const { userInfo } = props;
	const [sectionsData, setSectionsData] = useState<sectionsType[]>([]);
	const [sectionsName, setSectionsName] = useState<string>("");
	const [attendanceData, setAttendanceData] = useState<rec[]>([]);
	const [selectedSection, setSelectedSection] = useState<string>("");
	const [loadingSections, setLoadingSections] = useState(false);
	const [loadingAttendance, setLoadingAttendance] = useState(false);
	const { Option } = Select;
	const today: Date = new Date();
	const todayDate: string = format(today, "yyyy-MM-dd");
	const [dateValue, setDateValue] = useState(() => dayjs(todayDate));
	const [currentSession, setCurrentSession] = useState(0);
	const [t] = useTranslation("global");
	const [secSessionsData, setSecSessionsData] = useState<SessionsType[]>([]);
	const session = useContext(SessionsContext);
	const [editNote, setEditNote] = useState<rec>({
		studentId: "",
		attendance: "",
		firstname: "",
		notes: "",
		justified: false,
		duration: 0,
		sessionIndex: 0,
	});
	const [isEditing, setIsEditing] = useState(false);
	const [searchInput, setSearchInput] = useState<any>(null);

	const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
	const [fillAllCells, setFillAllCells] = useState(false);

	const handleDurationChange = debounce((value: number | null, record: rec, index: number) => {
		const updatedData = attendanceData.map((item) => {
			if (item.studentId === record.studentId) {
				return {
					...item,
					[`duration_${index}`]: value,
				};
			}
			return item;
		});
		const updatedRecord = updatedData.find((item) => item.studentId === record.studentId);
		if (updatedRecord) {
			setAttendanceData(updatedData);
			updateStatus(updatedRecord, index);
		}
	}, 1200);

	const handleDownloadClick = () => {
		setIsDownloadModalVisible(true);
	};

	const handleCheckboxChange = (e: any) => {
		setFillAllCells(e.target.checked);
	};

	const handleModalOk = () => {
		setIsDownloadModalVisible(false);
		downloadDailyAbsenceReport();
	};

	const handleModalCancel = () => {
		setIsDownloadModalVisible(false);
	};

	session.setSessions(secSessionsData[currentSession]);
	// const tableContainerRef = useRef<HTMLDivElement>(null);
	// const handleScrollLeft = () => {
	// 	if (tableContainerRef.current !== null) {
	// 		tableContainerRef.current.scrollLeft -= 100;
	// 	}
	// };

	// const handleScrollRight = () => {
	// 	if (tableContainerRef.current !== null) {
	// 		tableContainerRef.current.scrollLeft += 100;
	// 	}
	// };

	const onSelect = (value: Dayjs) => {
		setDateValue(value.startOf("day"));
	};

	const onPanelChange = (value: Dayjs) => {
		setDateValue(value.startOf("day"));
	};

	useEffect(() => {
		const getDefSection = async () => {
			const defSection = await getSectionsV2Api();
			setSelectedSection(defSection.data.sections[0].sectionId);
		};
		getDefSection();
	}, []);

	useEffect(() => {
		getSessions();
	}, [selectedSection, dateValue]);

	useEffect(() => {
		getSections();
	}, [selectedSection]);

	useEffect(() => {
		getAttendance(session.sessions?.start, session.sessions?.section);
	}, [session.sessions?.start, session.sessions?.section]);

	const data = sectionsData;

	const handleSectionChange = (value: string) => {
		setSelectedSection(value);
	};

	const getTagColor = (attendance: string) => {
		let color = "";

		switch (attendance) {
			case "present":
				color = "success";
				break;
			case "late":
				color = "warning";
				break;
			case "exited":
				color = "blue";
				break;
			case "absent":
				color = "error";
				break;
			default:
				color = "";
				break;
		}

		return color;
	};
	const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
		confirm();
	};

	const getColumnSearchProps = (dataIndex: string) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}: FilterDropdownProps) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={`${t("searchFor")}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: "block" }}
				/>
				<Button
					type="primary"
					onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
					{t("search")}
				</Button>
				<Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					{t("clear")}
				</Button>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? "#000000" : "#ffffff" }} />
		),
		onFilter: (value: string, record: rec) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

		onFilterDropdownVisibleChange: (visible: boolean) => {
			if (visible && searchInput) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
	});

	const handleReset = (clearFilters?: () => void) => {
		if (clearFilters) {
			clearFilters();
		}
	};

	const columns: ColumnsType<rec> = [
		{
			title: "#",
			dataIndex: "key",
			fixed: "left",
			align: "right",
			rowScope: "row",
			render: (_, __, index) => index + 1,
			width: "0.5em",
		},
		{
			key: "1",
			title: `${t("name")}`,
			dataIndex: "firstname",
			fixed: "left",
			align: "right",
			width: "3em",
			...(getColumnSearchProps("firstname") as ColumnType<rec>),
		},
		// @ts-ignore
		...secSessionsData.map((session, index) => ({
			key: `${index + 2}`,
			title:
				index === secSessionsData.length - 1
					? `${t("adminApproval")}`
					: `${t("lesson" + `${index + 1}`)}`,
			dataIndex: `session_${index}`,
			fixed: index === secSessionsData.length - 1 ? "right" : undefined,
			width: "2em",
			filters: [
				{ text: t("present"), value: "present" },
				{ text: t("late"), value: "late" },
				{ text: t("exited"), value: "exited" },
				{ text: t("absent"), value: "absent" },
			],
			filterIcon: (filtered: boolean) => (
				<FilterOutlined style={{ color: filtered ? "#000000" : "#ffffff" }} />
			),
			onFilter: (value: string, record: rec) => record[`attendance_${index}`] === value,
			render: (text: any, record: any) => {
				const sessionKey = `session_${index}`;
				const attendanceKey = `attendance_${index}`;
				const justifiedKey = `justified_${index}`;
				const durationKey = `duration_${index}`;
				const notesKey = `notes_${index}`;

				if (!record[attendanceKey]) return "-";

				return (
					<>
						<Dropdown overlay={getDropdownMenu(record, index)} trigger={["click"]}>
							<Tag color={getTagColor(record[attendanceKey])}>{t(record[attendanceKey])}</Tag>
						</Dropdown>
						{record[attendanceKey] !== "present" && record[attendanceKey] == "absent" && (
							<>
								<Tag
									onClick={() => updateJustified(record, index)}
									color={record[justifiedKey] ? "success" : "error"}
								>
									{record[justifiedKey] ? `${t("justified")}` : `${t("notJustified")}`}
								</Tag>
								{record[attendanceKey] === "late" && (
									<InputNumber
										addonBefore={<ClockCircleOutlined />}
										addonAfter={t("min")}
										style={{ width: "8em" }}
										value={record[durationKey]}
										onChange={(value: number | null) => {
											const updatedData = attendanceData.map((item) => {
												if (item.studentId === record.studentId) {
													return {
														...item,
														[durationKey]: value,
													};
												}
												return item;
											});

											const updatedRecord = updatedData.find(
												(item) => item.studentId === record.studentId
											);
											if (updatedRecord) {
												setAttendanceData(updatedData);
												updateStatus(updatedRecord, index);
											}
										}}
									/>
								)}
							</>
						)}
						{record[attendanceKey] !== "present" && (
							<Popover content={record[notesKey]} trigger="hover">
								<EditOutlined
									onClick={() => onEditNotes(record, index)}
									style={{
										fontSize: 20,
										color: record[notesKey] ? "#87d068" : "#000000",
										paddingTop: 7,
									}}
								/>
							</Popover>
						)}
					</>
				);
			},
		})),
	];

	const getDropdownMenu = (record: rec, index: number) => (
		<Menu onClick={({ key }) => handleDropdownClick(record, key, index)}>
			<Menu.Item key="present">{t("present")}</Menu.Item>
			<Menu.Item key="late">{t("late")}</Menu.Item>
			<Menu.Item key="exited">{t("exited")}</Menu.Item>
			<Menu.Item key="absent">{t("absent")}</Menu.Item>
		</Menu>
	);

	const handleDropdownClick = (record: rec, key: string, index: number) => {
		const updatedAttendanceData = [...attendanceData];
		const recordIndex = updatedAttendanceData.findIndex(
			(item) => item.studentId === record.studentId
		);

		if (recordIndex !== -1) {
			updatedAttendanceData[recordIndex][`attendance_${index}`] = key;
			updatedAttendanceData[recordIndex][`sessionId_${index}`] = record[`sessionId_${index}`];
			setAttendanceData(updatedAttendanceData);
			const params = {
				sessionId: record[`sessionId_${index}`],
				studentId: record.studentId,
				attendance: key,
				justified: record[`justified_${index}`],
				notes: record[`notes_${index}`],
				duration: record[`duration_${index}`],
				firstname: record.firstname,
			};
			if (key === "absent") {
				params.justified = record[`justified_${index}`];
			} else {
				params.justified = false;
			}
			updateAttendanceAdminApi(params)
				.then(function (response) {
					if (response.status === 200) {
						message.success(`${t("attendanceDataSavedSuccessfully")}`, 2);
					} else {
						message.error(`${t("attendanceDataSaveFailed")}`, 2);
					}
				})
				.catch(function (error) {
					message.error("Failed to update attendance: " + error.message, 2);
				});
		}
	};

	const updateJustified = (record: rec, sessionIndex: number) => {
		const sessionKey = `session_${sessionIndex}`;
		const justifiedKey = `justified_${sessionIndex}`;
		record[justifiedKey] = !record[justifiedKey];

		const params = {
			sessionId: record[`sessionId_${sessionIndex}`],
			studentId: record.studentId,
			attendance: record[`attendance_${sessionIndex}`],
			justified: record[justifiedKey],
			notes: record[`notes_${sessionIndex}`],
			duration: record[`duration_${sessionIndex}`],
			firstname: record.firstname,
		};

		if (record[`attendance_${sessionIndex}`] === "absent") {
			params.justified = record[justifiedKey];
		} else {
			params.justified = false;
		}

		updateAttendanceAdminApi(params)
			.then(function (response) {
				if (response.status === 200) {
					setAttendanceData((prevData) => {
						const updatedData = prevData.map((item) => {
							if (item.studentId === record.studentId) {
								return { ...item, justified: record.justified };
							}
							return item;
						});
						return updatedData;
					});
					message.success(`${t("attendanceDataSavedSuccessfully")}`, 2);
					if (record.attendance === "late" || record.attendance === "absent") {
						getAttendance(session.sessions?.start, session.sessions?.section);
					}
				}
			})
			.catch(function (error) {
				message.error("Failed to update attendance: " + error.message, 2);
			});

		setIsEditing(false);
	};

	const getSessions = () => {
		const params = {
			sectionId: selectedSection,
			startTimeFrom: `${dateValue?.format("YYYY-MM-DD")}` + "T00:00:00.000Z",
			startTimeEnd: `${dateValue?.format("YYYY-MM-DD")}` + "T23:59:00.000Z",
		};

		getSecSessionsApi(params)
			.then(function (response) {
				if (response.status === 200) {
					const newData: SessionsType[] = [
						...(response.data as SessionsType[]),
						{
							instructorId: "23-24#qa#1#" + userInfo.id,
							end: `${dateValue?.format("YYYY-MM-DD")}` + "T00:00:00.000Z",
							section: response.data[0].section,
							sectionId: "",
							start: `${dateValue?.format("YYYY-MM-DD")}` + "T00:00:00.000Z",
						},
					];
					setSecSessionsData(newData);
					session.setSessions(newData[currentSession]);
				}
			})
			.catch(function (error) {
				//message.error("Failed to load sessions data: " + error.message, 2);
			});
	};

	const onEditNotes = (record: rec, sessionIndex: number) => {
		setIsEditing(true);
		const updatedEditNote: rec = {
			...record,
			sessionIndex: sessionIndex,
		};
		for (let i = 0; i < secSessionsData.length; i++) {
			updatedEditNote[`notes_${i}`] = record[`notes_${i}`];
			updatedEditNote[`attendance_${i}`] = record[`attendance_${i}`];
			updatedEditNote[`duration_${i}`] = record[`duration_${i}`];
			updatedEditNote[`justified_${i}`] = record[`justified_${i}`];
		}
		setEditNote(updatedEditNote);
	};

	const getAttendance = (start: any, section: any) => {
		setLoadingAttendance(true);
		if (!session.sessions || !secSessionsData.length) return;

		const attendanceByStudent: AttendanceByStudent = {};
		const promises = secSessionsData.map((session, index) =>
			getAttendanceInSectionApi({
				sectionId: section,
				sessionStart: session.start,
			})
		);

		Promise.all(promises)
			.then((responses) => {
				responses.forEach((res, sessionIndex) => {
					const sessionStart = secSessionsData[sessionIndex].start;
					const attendanceDataForSession = res.data?.Items || [];

					attendanceDataForSession.forEach((item: any) => {
						const studentId = item.studentId;
						const attendanceKey = `attendance_${sessionIndex}`;
						const sessionIdKey = `sessionId_${sessionIndex}`;
						const justifiedKey = `justified_${sessionIndex}`;
						const durationKey = `duration_${sessionIndex}`;
						const notesKey = `notes_${sessionIndex}`;

						if (!attendanceByStudent[studentId]) {
							attendanceByStudent[studentId] = {
								studentId: item.studentId,
								firstname: item.firstname,
								notes: item.notes,
								justified: item.justified,
								duration: item.duration,
								attendance: "",
							};
						}
						attendanceByStudent[studentId][attendanceKey] = item.attendance;
						attendanceByStudent[studentId][
							sessionIdKey
						] = `${session.sessions?.section}#${sessionStart}`;
						attendanceByStudent[studentId][justifiedKey] = item.justified;
						attendanceByStudent[studentId][durationKey] = item.duration;
						attendanceByStudent[studentId][notesKey] = item.notes;
					});
				});

				const processedData: rec[] = Object.values(attendanceByStudent);
				setAttendanceData(
					processedData.sort((a, b) => a.firstname.localeCompare(b.firstname, "ar"))
				);
			})
			.catch((error) => {
				message.error("Failed to load attendance data: " + error.message, 2);
			})
			.finally(() => {
				setLoadingAttendance(false);
			});
	};

	const getSections = () => {
		setLoadingSections(true);
		getSectionsV2Api()
			.then((res) => {
				setSectionsData(res.data.sections);
				setSectionsName(res.data.sectionsName);
			})
			.catch((error) => {
				setLoadingSections(false);
			});
		setLoadingSections(false);
	};

	const updateStatus = (record: rec, sessionIndex: number) => {
		const params = {
			sessionId: record[`sessionId_${sessionIndex}`],
			studentId: record.studentId,
			attendance: record[`attendance_${sessionIndex}`],
			justified: record[`justified_${sessionIndex}`],
			notes: record[`notes_${sessionIndex}`],
			duration: record[`duration_${sessionIndex}`],
		};

		if (record[`attendance_${sessionIndex}`] === "absent") {
			params.justified = record[`justified_${sessionIndex}`];
		} else {
			params.justified = false;
		}
		updateAttendanceAdminApi(params)
			.then(function (response) {
				if (response.status === 200) {
					setAttendanceData((prevData) => {
						const updatedData = prevData.map((item) => {
							if (item.studentId === record.studentId) {
								return { ...item, ...record };
							}
							return item;
						});
						return updatedData;
					});
					message.success(`${t("attendanceDataSavedSuccessfully")}`, 2);
				} else {
					message.error(`${t("attendanceDataSaveFailed")}`, 2);
				}
			})
			.catch(function (error) {
				message.error("Failed to update attendance: " + error.message, 2);
			});

		setIsEditing(false);
	};

	const downloadDailyAbsenceReport = () => {
		const localDate = dayjs(dateValue).format("YYYY-MM-DD");
		const data = { sectionId: selectedSection, start: localDate, fillAllCells };

		const hideLoading = message.loading(t("absenceReport.attendanceReportDownloadIsInProgress"), 0);

		downloadDailyAbsenceReportApi(data)
			.then((res) => {
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute(
					"download",
					`daily_absence_report_${selectedSection.slice(-3).replace("#", "-")}_${dateValue.format(
						"YYYY-MM-DD"
					)}.xlsx`
				);
				document.body.appendChild(link);
				link.click();
				link.remove();

				// Hide loading message and show success message
				hideLoading();
				message.success(t("absenceReport.attendanceReportDownloadCompleted"), 2);
			})
			.catch(() => {
				// Hide loading message and show error message
				hideLoading();
				message.error(t("absenceReport.failedToDownloadAttendanceReport"), 2);
			});
	};

	message.config({
		top: 50,
		maxCount: 1,
	});

	return (
		<>
			<div style={{ margin: "auto", padding: "12px" }}>
				{t("class")} - {sectionsName}
			</div>
			<Form layout="inline" style={{ margin: "auto", padding: "0px 12px 20px 12px" }}>
				<Form.Item label={t("section")}>
					<Select
						showSearch
						style={{ width: 200 }}
						placeholder="Select a section"
						optionFilterProp="children"
						onChange={handleSectionChange}
						value={selectedSection}
					>
						{data.map((item: sectionsType) => (
							<Option key={item.sectionId} value={item.sectionId}>
								{item.section}
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item label={t("date")}>
					<DatePicker
						defaultValue={dayjs()}
						onSelect={onSelect}
						onPanelChange={onPanelChange}
						style={{ width: 200 }}
						disabledDate={(current) => {
							return current && current > dayjs().endOf("day");
						}}
					/>
				</Form.Item>

				<Form.Item>
					<Button icon={<DownloadOutlined />} onClick={handleDownloadClick}>
						{t("absenceReport.dailyReport")}
					</Button>
				</Form.Item>
				<Modal
					title={t("absenceReport.excelOptions")}
					open={isDownloadModalVisible}
					onOk={handleModalOk}
					onCancel={handleModalCancel}
					okText={t("download")}
					cancelText={t("cancel")}
				>
					<Checkbox onChange={handleCheckboxChange} checked={fillAllCells}>
						{t("absenceReport.fillAllCells")}
					</Checkbox>
				</Modal>
			</Form>
			{dateValue.day() === 6 || dateValue.day() === 5 ? (
				<div style={{ margin: "auto", padding: "12px" }}>{t("thereIsNoDataForTheWeekend")}</div>
			) : (
				<Row gutter={24}>
					<Col span={24}>
						{loadingAttendance || loadingSections ? (
							<div style={{ textAlign: "center", marginTop: "20px" }}>
								<Spin size="large" />
							</div>
						) : (
							<>
								{attendanceData.length !== 0 ? (
									<div>
										<div className="table-container">
											<Table columns={columns} dataSource={attendanceData} pagination={false} />
										</div>
										<Modal
											title={t("note")}
											visible={isEditing}
											centered
											okText={t("save")}
											cancelText={t("cancel")}
											onOk={() => updateStatus(editNote, editNote.sessionIndex)}
											onCancel={() => setIsEditing(false)}
											style={{ top: 20, maxHeight: "80vh", overflowY: "auto" }}
										>
											<Input
												value={
													editNote.sessionIndex !== undefined
														? editNote[`notes_${editNote.sessionIndex}`]
														: ""
												}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													setEditNote((prevNote) => ({
														...prevNote,
														[`notes_${prevNote.sessionIndex}`]: e.target.value,
													}))
												}
											/>
										</Modal>
										{/* <div
											style={{
												display: "flex",
												justifyContent: "space-between",
												padding: 12,
											}}
										>
											<RightOutlined onClick={handleScrollRight} />
											<LeftOutlined onClick={handleScrollLeft} />
										</div> */}
									</div>
								) : (
									<>
										<Card>
											<Card.Grid
												style={{
													width: "100%",
													textAlign: "center",
													fontSize: 20,
													border: "1px solid black",
													borderRadius: 25,
												}}
											>
												{t("thereIsNoData")}
											</Card.Grid>
										</Card>
									</>
								)}
							</>
						)}
					</Col>
				</Row>
			)}
		</>
	);
};
export default Admin;
