import React, { useEffect, useState } from "react";
import { List, Button, Modal, Badge, Dropdown, Menu } from "antd";
import {
	InboxOutlined,
	CloseOutlined,
	UserOutlined,
	CalendarOutlined,
	ClockCircleOutlined,
	CheckCircleOutlined,
	ExclamationCircleOutlined,
	BookOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { getAttendanceChangesApi } from "../api";

interface AttendanceResponse {
	oldAttendance: {
		attendance: string;
		justified: boolean;
	};
	newAttendance: {
		attendance: string;
		justified: boolean;
	};
	instructorId: string;
	sessionId: string;
	studentId: string;
	studentName: string;
	modifierId: string;
	changeTimestamp: string;
	modifiedBy: string;
	modifierName: string;
}

const Inbox = () => {
	const [t] = useTranslation("global");
	const [apiResponse, setApiResponse] = useState<AttendanceResponse[]>([]);
	const [unreadCount, setUnreadCount] = useState(0);
	const [messages, setMessages] = useState<
		{ summaryMessage: string; detailedMessage: JSX.Element; changeTimestamp: string }[]
	>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedMessage, setSelectedMessage] = useState<JSX.Element | null>(null);

	const fetchData = async () => {
		try {
			const response = await getAttendanceChangesApi();
			setApiResponse(response.data);
		} catch (error) {
			console.error(error);
		}
	};

	const generateMessage = (res: AttendanceResponse) => {
		const sectionParts = res.sessionId.split("#");
		const section =
			sectionParts.length >= 5 ? `${sectionParts[3]}/${sectionParts[4]}` : "undefined/undefined";
		const changeDateTime = new Date(res.changeTimestamp);
		const changeDate = isNaN(changeDateTime.getTime())
			? "Invalid Date"
			: changeDateTime.toLocaleDateString();
		const changeTime = isNaN(changeDateTime.getTime())
			? "Invalid Time"
			: changeDateTime.toLocaleTimeString("en-GB", { hour12: false });

		const dateTime = new Date(sectionParts[5]);
		const date = isNaN(dateTime.getTime()) ? "Invalid Date" : dateTime.toLocaleDateString();
		const time = isNaN(dateTime.getTime())
			? "Invalid Time"
			: dateTime.toLocaleTimeString("en-GB", { hour12: false });

		const summaryMessage = `${t("attendanceUpdate")}: ${res.studentName} - ${date} - ${time}`;

		const detailedMessage = (
			<>
				<p>
					<strong>
						{t("did")} {t("mr")} {res.modifierName} {t("hasChangedTheAttendance")}:
					</strong>
				</p>
				<p>
					<UserOutlined /> {t("student")}: {res.studentName}
				</p>
				<p>
					<CalendarOutlined /> {t("lessonDate")}: {date}
				</p>
				<p>
					<ClockCircleOutlined /> {t("lessonTime")}: {time}
				</p>
				{res.oldAttendance.attendance === res.newAttendance.attendance ? (
					<p>
						<CheckCircleOutlined /> {t("attendance")}: {t(`${res.newAttendance.attendance}`)}
					</p>
				) : (
					<p>
						<CheckCircleOutlined /> {t("attendance")}: {t("from")}{" "}
						{t(`${res.oldAttendance.attendance}`)} {t("to")} {t(`${res.newAttendance.attendance}`)}
					</p>
				)}
				{res.oldAttendance.justified === res.newAttendance.justified ? (
					<p>
						<ExclamationCircleOutlined /> {t("justified")}:{" "}
						{res.newAttendance.justified ? t("yes") : t("no")}
					</p>
				) : (
					<p>
						<ExclamationCircleOutlined /> {t("justified")}: {t("from")}{" "}
						{res.oldAttendance.justified ? t("yes") : t("no")} {t("to")}{" "}
						{res.newAttendance.justified ? t("yes") : t("no")}
					</p>
				)}

				<p>
					<BookOutlined /> {t("section")}: {section}
				</p>
				<p>
					<CalendarOutlined /> {t("changeDate")}: {changeDate}
				</p>
				<p>
					<ClockCircleOutlined /> {t("changeTime")}: {changeTime}
				</p>
			</>
		);

		return { summaryMessage, detailedMessage, changeTimestamp: res.changeTimestamp };
	};

	const updateUnreadCount = (messages: any[]) => {
		const lastViewed = localStorage.getItem("lastViewedTimestamp");
		const newMessages = messages.filter(
			(msg) => new Date(msg.changeTimestamp) > new Date(lastViewed || 0)
		);
		setUnreadCount(newMessages.length);
	};

	const handleViewMessages = () => {
		const currentTime = new Date().toISOString();
		localStorage.setItem("lastViewedTimestamp", currentTime);
		setUnreadCount(0);
	};

	const clearAllMessages = () => {
		setMessages([]);
	};

	const inboxMenu = (
		<Menu>
			{messages.length === 0 ? (
				<Menu.Item>{t("noMessages")}</Menu.Item>
			) : (
				messages.map((message, index) => (
					<Menu.Item key={index} onClick={() => showModal(message.detailedMessage)}>
						{message.summaryMessage}
					</Menu.Item>
				))
			)}
			{messages.length > 0 && (
				<Menu.Item>
					<Button type="link" onClick={clearAllMessages} icon={<DeleteOutlined />}>
						{t("clearAll")}
					</Button>
				</Menu.Item>
			)}
		</Menu>
	);

	// Show modal with detailed message
	const showModal = (message: JSX.Element) => {
		setSelectedMessage(message);
		setIsModalOpen(true);
	};

	// Close modal
	const handleClose = () => {
		setIsModalOpen(false);
	};

	// Effect to fetch data and generate messages
	useEffect(() => {
		fetchData();
	}, []);

	// Effect to generate messages and calculate unread count
	useEffect(() => {
		const generatedMessages = apiResponse.map((res) => generateMessage(res));
		setMessages(generatedMessages);
		updateUnreadCount(generatedMessages);
	}, [apiResponse]);

	return (
		<div style={{ position: "relative", display: "inline-block", marginTop: 11 }}>
			<Dropdown overlay={inboxMenu} trigger={["click"]}>
				<Button
					type="text"
					icon={
						<Badge count={unreadCount}>
							<InboxOutlined />
						</Badge>
					}
					onClick={handleViewMessages}
				/>
			</Dropdown>
			<Modal
				title={t("attendanceUpdate")}
				open={isModalOpen}
				onCancel={handleClose}
				footer={null}
				centered
				style={{ top: 20 }}
			>
				<Button
					type="text"
					onClick={handleClose}
					style={{
						position: "absolute",
						insetInlineEnd: 0,
						top: 12,
						fontSize: "16px",
						color: "#000",
						border: "none",
						background: "transparent",
					}}
				></Button>
				<div>{selectedMessage}</div>
			</Modal>
		</div>
	);
};

export default Inbox;
