import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Table, Space } from "antd";
import { getLessonsScheduleApi } from "../api";
interface ScheduleItem {
	time: string;
	sectionId: string;
	instructorId: string;
	instructorName: string;
	title: string;
	dayId: number;
	day: string;
	section: string;
}
interface GroupedSchedule {
	day: string;
	schedules: { time: string; section: string }[];
}
interface Schedules {
	time: string;
	section: string;
}
interface GroupedScheduless {
	day: string;
	schedules: Schedules[];
}

const LessonsSchedule = (props: any) => {
	const { lang } = props;
	const [t, i18n] = useTranslation("global");
	const [dataSourc, setDataSource] = useState([]);
	const [loading, setLoading] = useState(false);
	const data: ScheduleItem[] = dataSourc;

	const groupedData: GroupedSchedule[] = [];

	data.forEach((item) => {
		const translatedDay = t(item.day.toLowerCase());
		const existingDay = groupedData.find((group) => group.day === translatedDay);
		const timeSectionPair = { time: item.time, section: item.section };

		if (existingDay) {
			existingDay.schedules.push(timeSectionPair);
		} else {
			groupedData.push({ day: translatedDay, schedules: [timeSectionPair] });
		}
	});

	const dataSource: GroupedScheduless[] = groupedData;

	useEffect(() => {
		getLessonsSchedule();
	}, [lang]);

	// Extract unique times and sort them
	const uniqueTimes = Array.from(
		new Set(dataSource.flatMap((item) => item.schedules.map((schedule) => schedule.time)))
	).sort((a, b) => {
		// Custom sorting function to handle time strings
		const [aHours, aMinutes] = a.split(" - ")[0].split(":").map(Number);
		const [bHours, bMinutes] = b.split(" - ")[0].split(":").map(Number);

		const aTotalMinutes = aHours * 60 + aMinutes;
		const bTotalMinutes = bHours * 60 + bMinutes;
		return aTotalMinutes - bTotalMinutes;
	});

	const columns = [
		{
			title: `${t("day")}`,
			dataIndex: "day",
			key: "day",
		},
		...uniqueTimes.map((time) => ({
			title: (
				<>
					<>
						{time === "7:10 - 7:55" && `${t("lesson1")}`}
						{time === "8:00 - 8:45" && `${t("lesson2")}`}
						{time === "8:50 - 9:35" && `${t("lesson3")}`}
						{time === "10:00 - 10:45" && `${t("lesson4")}`}
						{time === "10:50 - 11:35" && `${t("lesson5")}`}
						{time === "11:55 - 12:40" && `${t("lesson6")}`}
						{time === "12:40 - 13:25" && `${t("lesson7")}`}
					</>
					<br /> {time}
				</>
			),
			dataIndex: time,
			key: time,
			render: (_: any, record: GroupedScheduless) => {
				const schedule = record.schedules.find((s) => s.time === time);
				return schedule ? schedule.section : "-";
			},
		})),
	];

	const getLessonsSchedule = () => {
		setLoading(true);
		getLessonsScheduleApi(4, {
			headers: {
				lang: lang,
			},
		}).then((res) => {
			setDataSource(res.data);
			setLoading(false);
		});
	};

	return (
		<div>
			<div style={{ textAlign: "center" }}>
				<h1>{t("lessonsSchedule")}</h1>
			</div>
			<Table loading={loading} columns={columns} dataSource={dataSource} pagination={false}></Table>
		</div>
	);
};
export default LessonsSchedule;
