import React, { useState, useEffect, useContext } from "react";
import { Steps } from "antd";
import { SessionsType, SessionsContext } from "../useContext/SessionsContext";
import { getSessionsApi } from "../api";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const Sessions = () => {
	const [current, setCurrent] = useState(0);
	const [dataSource, setDataSource] = useState([]);
	const session = useContext(SessionsContext);
	const [t] = useTranslation("global");
	const today: Date = new Date();

	useEffect(() => {
		getSessions();
	}, []);

	const onChange = (value: number) => {
		setCurrent(value);
	};

	const items = dataSource.map((data: SessionsType) => {
		const start = new Date(data.start);
		const end = new Date(data.end);

		start.setHours(start.getHours() - 6);
		end.setHours(end.getHours() - 6);

		return {
			title: `${t("section")}`,
			subTitle: data.section,
			description: [
				start.toTimeString().substring(0, 5),
				" - ",
				end.toTimeString().substring(0, 5),
			],
		};
	});
	session.setSessions(dataSource[current]);

	const getSessions = () => {
		getSessionsApi().then((res) => {
			setDataSource(res.data);
		});
	};

	const todayDate: string = format(today, "dd - MM - yyyy");
	const day: string = format(today, "EEEE"); // Retrieves the full day name

	return (
		<>
			<div style={{ textAlign: "center" }}>
				<h1>
					<>
						{day === "Sunday" && `${t("sunday")}`}
						{day === "Monday" && `${t("monday")}`}
						{day === "Tuesday" && `${t("tuesday")}`}
						{day === "Wednesday" && `${t("wednesday")}`}
						{day === "Thursday" && `${t("thursday")}`}
					</>{" "}
					{todayDate}
				</h1>
			</div>
			<Steps current={current} onChange={onChange} items={items} />
		</>
	);
};

export default Sessions;
