import { fetchUserAttributes } from "@aws-amplify/auth";

const UserAuthInfo = async () => {
  try {
    const user = await fetchUserAttributes();

    const email = user.email;
    const id = user?.["custom:id"];
    const role = user?.["custom:role"];

    return {
      email,
      id,
      role,
    };
  } catch (error) {
    console.error("Error fetching user attributes:", error);
    return null;
  }
};

export default UserAuthInfo;
