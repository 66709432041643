import React, { useState, useContext, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import {
	PieChartOutlined,
	UserOutlined,
	LogoutOutlined,
	SettingOutlined,
	TableOutlined,
	FileTextOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu, theme, Space, Avatar, Select, ConfigProvider, Dropdown, Image } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Sessions from "./Sessions";
import Attendance from "./Attendance";
import Attendances from "./Attendances";
import LessonsSchedule from "./LessonsSchedule";
import ExamSchedule from "./ExamSchedule";
import { AlertsContext } from "../useContext/AlertContext";
import { useTranslation } from "react-i18next";
import { DirectionType } from "antd/es/config-provider";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Admin from "./Admin";
import SchoolSettings from "./SchoolSettings";
import AbsenceReport from "./AbsenceReport";
import UserAuthInfo from "../utils/UserAuthInfo";
import Inbox from "./Inbox";
import Account from "./Account";
interface UserInfo {
	email: string | undefined;
	id: string | undefined;
	role: string | undefined;
}

const Contents = () => {
	const { user, signOut } = useAuthenticator((context) => [context.user]);
	const [collapsed, setCollapsed] = useState(false);
	const {
		token: { colorBgContainer },
	} = theme.useToken();
	const [routeKey, setRouteKey] = useState("");
	const navigate = useNavigate();
	const alerts = useContext(AlertsContext);
	const [t, i18n] = useTranslation("global");
	const { Header, Content, Footer, Sider } = Layout;
	const [direction, setDirection] = useState<DirectionType | string | null>("rtl");
	const [language, setLanguage] = useState("ar");
	const location = useLocation();
	type MenuItem = Required<MenuProps>["items"][number];
	const selectedNavSection = location.pathname.split("/")[1];
	const [logo, setLogo] = useState("");
	const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

	const onNavigate = (route: any) => {
		setRouteKey(route.key);
		navigate(`/${route.key}`);
	};

	useEffect(() => {
		const fetchUserInfo = async () => {
			const fetchedUserInfo: UserInfo | null = await UserAuthInfo();
			setUserInfo(fetchedUserInfo);
		};
		fetchUserInfo();
	}, []);

	function getItem(
		label: React.ReactNode,
		key: React.Key,
		icon?: React.ReactNode,
		children?: MenuItem[]
	): MenuItem {
		const background = key === "lessons-schedule" && selectedNavSection === "" ? "#1677ff" : "";
		return {
			key,
			icon,
			children,
			label,
			style: { background },
		} as MenuItem;
	}

	const items: MenuItem[] = [];

	if (userInfo?.role === "admin") {
		items.push(
			getItem(`${t("attendance")}`, "attendance", <PieChartOutlined />),
			getItem(`${t("schoolSettings.title")}`, "school-settings", <SettingOutlined />),
			getItem(`${t("reports")}`, "absence-report", <FileTextOutlined />)
		);
	} else if (userInfo?.role === "teacher") {
		items.push(
			getItem(`${t("attendance")}`, "attendance", <PieChartOutlined />),
			getItem(`${t("lessonsSchedule")}`, "lessons-schedule", <TableOutlined />)
		);
	}

	const changeLang = (lang: string) => {
		i18n.changeLanguage(lang);
		setDirection(lang);
		setLanguage(lang);
	};

	const routes = useRoutes([
		{
			path: "/",
			element: userInfo?.role === "teacher" ? <LessonsSchedule lang={language} /> : <Attendances />,
		},
		{
			path: "/attendance",
			element: (
				<>
					<Attendances />
				</>
			),
		},
		{
			path: "/account",
			element: (
				<>
					<Account userInfo={userInfo} />
				</>
			),
		},
		...(userInfo?.role === "admin"
			? [
					{
						path: "/school-settings",
						element: <SchoolSettings />,
					},
					{
						path: "/absence-report",
						element: <AbsenceReport />,
					},
			  ]
			: [
					{
						path: "/lessons-schedule",
						element: (
							<>
								<LessonsSchedule />
							</>
						),
					},
			  ]),
	]);

	const avatarMenuProps = {
		items: [
			{
				label: `${t("account")}`,
				key: "account",
				icon: <UserOutlined />,
			},
			{
				label: `${t("logout")}`,
				key: "logout",
				icon: <LogoutOutlined style={{ color: "red" }} />,
			},
		],
		onClick: ({ key }: { key: string }) => {
			if (key === "logout") {
				signOut();
				navigate("/");
			}
			if (key === "account") {
				navigate("/account");
			}
		},
	};

	return (
		<ConfigProvider
			direction={language === "ar" ? "rtl" : "ltr"}
			theme={{
				token: {
					fontFamily: "Baloo Bhaijaan 2",
				},
			}}
		>
			<Layout style={{ minHeight: "100vh" }}>
				<Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
					{logo ? (
						<Image src={logo} alt="logo" />
					) : (
						<Image src="/eedara_logo.png" alt="default logo" />
					)}
					{/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '6px'}}>
            <Upload
              name="logo"
              beforeUpload={(file) => {
                setLogo(URL.createObjectURL(file));
                return false;
              }}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}> Logo</Button>
            </Upload>
          </div> */}
					<Menu
						theme="dark"
						defaultSelectedKeys={["attendance"]}
						selectedKeys={[selectedNavSection]}
						mode="inline"
						onClick={(e: any) => {
							onNavigate(e);
						}}
						items={items}
					></Menu>
				</Sider>
				<Layout>
					<Header style={{ padding: 0, background: colorBgContainer }}>
						<Space
							size={16}
							wrap
							style={{
								display: "flex",
								justifyContent: "space-between",
								padding: "0px 14px",
							}}
						>
							<div style={{ display: "flex", alignItems: "center", gap: 14 }}>
								<Dropdown menu={avatarMenuProps} trigger={["click"]}>
									<div onClick={(e) => e.preventDefault()}>
										<Avatar style={{ backgroundColor: "#87d068" }} icon={<UserOutlined />} />
									</div>
								</Dropdown>
								<div>
									<Inbox />
								</div>
								<div>
									{user.signInDetails?.loginId ? user.signInDetails.loginId.split("@")[0] : ""}
								</div>
							</div>

							<div style={{ display: "flex", alignItems: "center" }}>
								<Select
									defaultValue="ar"
									style={{ width: 120 }}
									onChange={changeLang}
									options={[
										{ value: "ar", label: "العربية" },
										{ value: "en", label: "English" },
									]}
								/>
							</div>
						</Space>
					</Header>

					<Content style={{ margin: 16, background: colorBgContainer }}>
						<div style={{ padding: 8, background: colorBgContainer }}>{routes}</div>
						{/* <Space direction="vertical" style={{ width: "100%" }}>
            <Alerts />
          </Space> */}
					</Content>
					<Footer>©2024 Powered by EEDARA</Footer>
				</Layout>
			</Layout>
		</ConfigProvider>
	);
};

export default Contents;
