import React from "react";
import { Card } from "antd";
import { UserOutlined, IdcardOutlined, SafetyOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Account = (props: any) => {
	const { userInfo } = props;
	const [t] = useTranslation("global");

	return (
		<div style={{ padding: "20px" }}>
			<Card title={t("accountInformation")}>
				<h3>
					<UserOutlined /> {t("email")} : {userInfo?.email}
				</h3>
				<h3>
					<IdcardOutlined /> {t("userId")} : {userInfo?.id}
				</h3>
				<h3>
					<SafetyOutlined /> {t("job")} : {t(`${userInfo?.role}`)}
				</h3>
			</Card>
		</div>
	);
};

export default Account;
